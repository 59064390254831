@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import 'primeflex/primeflex.scss';

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: var(--font-family);
  font-color: var(--text-color);
}

#headerMenuHandler {
  width: 20%;
}

#headerTitle {
  width: 20%;
}

#headerSearch {
  width: 60%;
}

#searchInput {
  max-width: 230px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.header {
  justify-self: flex-start;
  background: url("/header-background.png");
  background-repeat: repeat-x;
  background-size: contain;
  padding: 0;
  height: 120px;
  min-height: 120px;
}

.content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.footer {
  justify-self: flex-end;
  text-align: right;
  background-color: #e1e0e0;
  padding: 3px;
  font-size: 0.8em;
}

.p-button.p-button-sm {
  width: 2.3rem !important;
  height: 2.3rem !important;
}

.grid-row:hover {
  background-color: var(--blue-100);
}

.grid-row:nth-child(odd) {
  background-color: var(--gray-100);
}

@media only screen and (min-width: 992px) {
  #headerMenuHandler {
    width: 5%;
  }

  #headerTitle {
    width: 65%;
  }

  #headerSearch {
    width: 30%;
  }

  #searchInput {
    max-width: unset;
  }

  .p-tieredmenu {
    height: 100%;
    border-radius: 0;
    border-top-style: none;
    border-bottom-style: none;
    background-color: #e1e0e0;
  }

  p-tieredmenu.expanded > div.p-tieredmenu {
    width: 12.5rem !important;
  }

  p-tieredmenu.contracted > div.p-tieredmenu {
    width: 4.5rem !important;
  }

  .p-tieredmenu .p-submenu-list {
    border-radius: 0;
    background-color: #e1e0e0;
  }

  .p-autocomplete-dd {
    width: 100%!important;
  }
}
